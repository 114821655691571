import React from 'react'

export const SidebarData= [
    {
        title: "[SNG]⠀Ghost Recon (Ephemeral Bootleg) (2023)",
        link: "/GHOSTRECONEPHEMERALBOOTLEG"
    },

    {
        title: "[MIX]⠀ briskwalk RADIO 007 (A Second Gun) (2023)",
        link: "/BRISKWALKRADIO007"
    },

    {
        title: "[RDO]⠀A Second Gun @ BALAMII (2023)",
        link: "/ASECONDGUNBALAMII"
    },

    {
        title: "[MIX]⠀ briskwalk RADIO 006 (MIKI) (2023)",
        link: "/BRISKWALKRADIO006"
    },

    {
        title: "[PRJ]⠀MKDRIVER (2023)",
        link: "/MKDRIVER"
    },

    {
        title: "[RDO]⠀Youth Eternal w/ MOONKAY + MK (2023)",
        link: "/YOUTHETERNAL"
    },

    {
        title: "[PRJ]⠀Eleven Wound (2022)",
        link: "/ELEVENWOUND"
    },

    {
        title: "[PRJ]⠀MMETALKNIGHTT ep! (2020)",
        link: "/MMETALKNIGHTTEP"
    }
    


]
